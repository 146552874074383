import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Partenaires() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
          <span class="relative inline-block text-blue-900">Nos partenaires</span>
        </h2>
        {/* <p class="text-base text-gray-700 md:text-lg text-center">
          Découvrez les challenges qui nous portent et nous animent pour construire l'avenir de nos étudiants
        </p> */}
        <div class="flex mt-6 justify-center">
          <div class="w-16 h-1 rounded-full bg-blue-900 inline-flex"></div>
        </div>
      </div>
      <div className="grid max-w-screen-lg gap-8 row-gap-5 mb-8 sm:grid-cols-1 lg:grid-cols-1 sm:mx-auto ">
        <div className='flex  items-center justify-center '>
          <a href="https://www.aefe.fr/" rel="noopenner noreferrer" target="_blank">
            <StaticImage
              src="../images/aefe-photo.png"
              quality={100}
              alt="Otos Lab logo"
              loading="eager"
              height="200"
              className="rounded shadow-lg"
            />
          </a>
          <a href="https://www.facebook.com/AFKairouan/" rel="noopenner noreferrer" target="_blank">
            <StaticImage
              src="../images/alliance-francaise.jpeg"
              quality={100}
              height="200"
              alt="Otos Lab logo"
              loading="eager"
              className="rounded shadow-lg ml-8"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
