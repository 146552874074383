import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AnimationRevealPage from '../../components/AnimationRevealPage';

import Partenaires from '../../components/Partenaires';

export default function NosPartenaires() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Nos partenaires"
          description="L'AEFE et L'alliance Française de Kairouan sont les partenaires officiels du Collège Français Jules Verne de Kairouan"
        />
        {/* <Valeurs /> */}
        <Partenaires />
      </Layout>
    </AnimationRevealPage>
  );
}
